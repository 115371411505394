/*
 * BOILERPLATE - COLORS
 */


/* WARM GREY */
$l-gray-100: #FAF9F7; $l-gray-200: #E8E6E1; $l-gray-300: #D3CEC4;
$l-gray-400: #B8B2A7; $l-gray-500: #A39E93; $l-gray-600: #857F72;
$l-gray-700: #625D52; $l-gray-800: #504A40; $l-gray-900: #423D33;
$l-gray-1000: #27241D;

/* COOL GREY
$l-gray-100: #F5F7FA; $l-gray-200: #E4E7EB; $l-gray-300: #CBD2D9;
$l-gray-400: #9AA5B1; $l-gray-500: #7B8794; $l-gray-600: #616E7C;
$l-gray-700: #52606D; $l-gray-800: #3E4C59; $l-gray-900: #323F4B;
$l-gray-1000: #1F2933;  */

/* BLUE GREY
$l-gray-100: #F0F4F8; $l-gray-200: #D9E2EC; $l-gray-300: #BCCCDC;
$l-gray-400: #9FB3C8; $l-gray-500: #829AB1; $l-gray-600: #627D98;
$l-gray-700: #486581; $l-gray-800: #334E68; $l-gray-900: #243B53;
$l-gray-1000: #102A43; */

/* GREY 
$l-gray-100: #F7F7F7; $l-gray-200: #E1E1E1; $l-gray-300: #CFCFCF;
$l-gray-400: #B1B1B1; $l-gray-500: #9E9E9E; $l-gray-600: #7E7E7E;
$l-gray-700: #626262; $l-gray-800: #515151; $l-gray-900: #3B3B3B;
$l-gray-1000: #222222;*/

$l-red-100: #FFEEEE;
$l-red-200: #FACDCD;
$l-red-300: #F29B9B;
$l-red-400: #E66A6A;
$l-red-500: #D64545;
$l-red-600: #BA2525;
$l-red-700: #A61B1B;
$l-red-800: #911111;
$l-red-900: #780A0A;
$l-red-1000: #610404;

$l-blue-100: #DCEEFB ;
$l-blue-200: #B6E0FE ;
$l-blue-300: #84C5F4 ;
$l-blue-400: #62B0E8 ;
$l-blue-500: #4098D7 ;
$l-blue-600: #2680C2 ;
$l-blue-700: #186FAF ;
$l-blue-800: #0F609B ;
$l-blue-900: #0A558C ;
$l-blue-1000: #003E6B ;

// Manca il 100: BS ha 9 sfumature, noi 10
$gray-100:  $l-gray-200;
$gray-200:  $l-gray-300;
$gray-300:  $l-gray-400;
$gray-400:  $l-gray-500;
$gray-500:  $l-gray-600;
$gray-600:  $l-gray-700;
$gray-700:  $l-gray-800;
$gray-800:  $l-gray-900;
$gray-900:  $l-gray-1000;

// Dovrebbero essere dininti in temrni deipreceneti
$l-primary-100: #DCEEFB !default;
$l-primary-200: #B6E0FE !default;
$l-primary-300: #84C5F4 !default;
$l-primary-400: #62B0E8 !default;
$l-primary-500: #4098D7 !default;
$l-primary-600: #2680C2 !default;
$l-primary-700: #186FAF !default;
$l-primary-800: #0F609B !default;
$l-primary-900: #0A558C !default;
$l-primary-1000: #003E6B !default;

$l-primary-2-100: #FFFAEB !default;
$l-primary-2-200: #FCEFC7 !default;
$l-primary-2-300: #F8E3A3 !default;
$l-primary-2-400: #F9DA8B !default;
$l-primary-2-500: #F7D070 !default;
$l-primary-2-600: #E9B949 !default;
$l-primary-2-700: #C99A2E !default;
$l-primary-2-800: #A27C1A !default;
$l-primary-2-900: #7C5E10 !default;
$l-primary-2-1000: #513C06 !default;


/*
 * BOILERPLATE - SPACING
 */

$gap-1:    2px;
$gap-2:    4px;
$gap-3:    8px;
$gap-4:   12px;
$gap-5:   16px;
$gap-6:   24px;
$gap-7:   32px;
$gap-8:   48px;
$gap-9:   64px;
$gap-10:  96px;
$gap-11: 128px;
$gap-12: 192px;

.l-tm-0 { margin-top:    0 }
.l-bm-0 { margin-bottom: 0 }
.l-tm-1 { margin-top:    $gap-1 !important }
.l-bm-1 { margin-bottom: $gap-1 !important }
.l-tm-2 { margin-top:    $gap-2 !important }
.l-bm-2 { margin-bottom: $gap-2 !important }
.l-tm-3 { margin-top:    $gap-3 !important }
.l-bm-3 { margin-bottom: $gap-3 !important }
.l-tm-4 { margin-top:    $gap-4 !important }
.l-bm-4 { margin-bottom: $gap-4 !important }
.l-tm-5 { margin-top:    $gap-5 !important }
.l-bm-5 { margin-bottom: $gap-5 !important }
.l-tm-6 { margin-top:    $gap-6 !important }
.l-bm-6 { margin-bottom: $gap-6 !important }
.l-tm-7 { margin-top:    $gap-7 !important }
.l-bm-7 { margin-bottom: $gap-7 !important }
.l-tm-8 { margin-top:    $gap-8 !important }
.l-bm-8 { margin-bottom: $gap-8 !important }
.l-tm-9 { margin-top:    $gap-9 !important }
.l-bm-9 { margin-bottom: $gap-9 !important }
.l-tm-10 { margin-top:    $gap-10 !important }
.l-bm-10 { margin-bottom: $gap-10 !important }
.l-tm-11 { margin-top:    $gap-11 !important }
.l-bm-11 { margin-bottom: $gap-11 !important }
.l-tm-12 { margin-top:    $gap-12 !important }
.l-bm-12 { margin-bottom: $gap-12 !important }


// ------------------------------------------------------------------------
// Variables (Bootstrap customization  + theme specific)
// ------------------------------------------------------------------------



// $body-bg: #f2e9dd !default;

/*
font-family: 'Poppins', sans-serif;
font-family: 'Fira Sans', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'IBM Plex Sans', sans-serif;
*/

// $font-family-serif: 'IBM Plex Serif' !default;
$font-family-sans-serif: 'Poppins' !default;
$l-font-family-brand: 'IM FELL DW Pica SC' !default;

$ll-bg_dark: #a2cbcd;
$ll-bg_light: #f2e9dd;

//$font-family-base: $font-family-serif;
$font-size-base: 1.0625rem !default;  // 17px per browser com rem di 16px
$font-size-lg: 1.375rem !default;      // 22px per browser com rem di 16px
$font-size-huge: 1.75rem !default;      // 28px per browser com rem di 16px

$l-font-family-ui: Poppins;
$font-size-ui: 1rem !default;

$loc-font-weight-light: 200;
$loc-font-weight-bold: 700;

$h1-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.75 !default;
$h3-font-size:                $font-size-base * 1.5 !default;

$primary: #C65D21;

/*
$nav-tabs-border-color: #adb5bd;
$nav-tabs-link-active-border-color: #adb5bd;
*/

// TODO - BORDI NULLI -> FORMALE


$border-radius: .45rem !default;
$border-radius-lg: .5rem !default;
$border-radius-sm: .4rem !default;


/* Customizzare alla luce di recactor UI 
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
*/

$navbar-padding-x: 0;
//$navbar-padding-y: 1.8rem;
$navbar-light-color: black !important;
// $navbar-light-toggler-icon-bg: none;
$navbar-light-toggler-border-color: transparent;

$input-btn-font-family: $l-font-family-ui !important;


@import "bootstrap";

// ------------------------------------------------------------------------
// Styles (customization of Bootstrap styles + new site specific ones)
// ------------------------------------------------------------------------

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


h1 {
  // font-weight: $loc-font-weight-light;
  font-weight: $loc-font-weight-bold;
  color: $l-gray-800;
  margin-bottom: $gap-5 !important;
}

.l-h1-ui {
  font-weight: $loc-font-weight-bold;
  font-size: $font-size-base;
}




.l-lead {
  font-size: $font-size-lg;
  font-weight: $loc-font-weight-bold;
}
.l-text-lg {
  font-size: $font-size-lg;
}
.l-text-huge {
  font-size: $font-size-huge;
}

.l-text-secondary {
  color: $l-gray-600;
}
.l-brand {
  //font-weight: $loc-font-weight-bold;
  font-size: $font-size-lg;
  font-variant: small-caps;
  //font-size: $h1-font-size;
  color:  $l-primary-900;

}

b, strong {
// Di solito usiamo **900** ma quesot fono arriva a 700
  // $font-weight-bold: 700; non funziona, pare un problema comune
  font-weight: $loc-font-weight-bold;
}


.l-font-ui {
  font-family: $l-font-family-ui;
  font-size: $font-size-ui;
  a {
    color: $l-gray-900 ! important;
    border-bottom: 1px solid gray
  }
  a.active {
      border-bottom: 4px solid black
  }

}

.l-link-unstyled, .l-link-unstyled:link, .l-link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}
.l-link-unstyled:hover {
  text-decoration: underline;
}

a.l-rl { // Real Link
  text-decoration: underline;
  font-weight: $loc-font-weight-bold;
  color: $primary;
}

.l-text-block {
    max-width: 45rem;
}

.l-mw-txt { max-width: 45rem; }
.l-mw-box { max-width: 35rem; }
.ll-mw-30 { max-width: 30rem; }
.l-mw-25 { max-width: 25rem; }
.ll-mw-20 { max-width: 20rem; }


.btn-cta {
  background-color: #E9B949 ! important;
  border-color: #E9B949 ! important;
  font-weight: $loc-font-weight-bold;
  font-variant: small-caps;
  letter-spacing: 2px;
  // font-size: $font-size-lg;

}


.card-footer {
  background-color: $l-gray-100;
}

.l-bg-light {
  background-color: $l-gray-200;
}

/*
.ll-bg-light {
  background-color: $ll-bg_light
}
*/

.ll-header {
  background-color: $ll-bg_light;
  .ll-menu {
    background-color: $ll-bg_dark;
    font-family: $l-font-family-brand;
    box-shadow: 0 8px 4px -4px gray;
  }
}


$menu-font-size: $font-size-base * 1.4 !important;
.ll-menu-font {
    font-family: $l-font-family-brand;
    font-size: $menu-font-size;
}

    .dropdown-item {
    font-family: $l-font-family-ui !important;
    }

.l-footer {
  margin-top: auto;
  background-color: $ll-bg-light;
  box-shadow: inset 1px 4px 9px -6px;
  padding-top: 1rem;

}

.l-envelope {
  padding: 1em; 
  border: 10px solid transparent;
  border-image: 16 repeating-linear-gradient(
    -45deg,
    $l-red-500 0, $l-red-500 0.75em,
    transparent 0, transparent 1.5em,
    $l-blue-600 0, $l-blue-600 2.25em,
    transparent 0, transparent 3em
  );

}



.l-card-ooter-funstuled {
  border-top: 0;
  background-color: transparent;
  padding-top: 0;
}

.ll-breadcrumbs {
  a {
    color: $l-gray-800;
  }
  a::after {
    content: " »"; 
  }
}

.ll-hp-label {
  // bottom: 20%;
  top: 95%;
  left: 0;
  width: 100%;
  //font-family: $l-font-family-brand;
  font-size: $h3-font-size;
  font-weight: $loc-font-weight-bold;
  p {
    background-color:  rgba(255, 255, 255, 0.75);
    padding: 10px 20px;
    color: black;
    margin-left: 1rem;
    margin-right: 1rem;
  }

}


